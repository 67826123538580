import { BaseUser } from '../User';
import type { User } from '../User';

interface OnboardingUserInterface extends User {
	spokenLangs: string[][];
}

class OnboardingUser extends BaseUser implements OnboardingUserInterface {
	get spokenLangs() {
		return this.user.spokenLangs
			? this.user.spokenLangs
					.sort()
					.map((l) => l.split(':'))
					.filter((l) => !/\d/u.test(l[0]))
			: [];
	}

	set spokenLangs(langs) {
		if (!langs || !Array.isArray(langs)) return;
		this.user = Object.assign(this.user, { spokenLangs: langs });
	}
}

const User = new OnboardingUser();

export { User };
