import ModelClass from '../Model.js';

class ModelOnboarding extends ModelClass {
	onAPIworkAcceptForceError(error, path, response) {
		return this.resendAfterForceDialog(error, path, response);
	}
}

const Model = new ModelOnboarding();
export { Model };
