import { commonDefaultRoute } from '../../router/defaultroute';

export const guiRoutes: { [path: string]: Route } = {
	'/talent/:talentId/security': {
		...commonDefaultRoute,
		as: 'security',
		isComponent: true,
	},
	'/talent/:talentId/:settingsgroup': {
		...commonDefaultRoute,
		as: 'talent',
	},
	'/talent/:talentId': {
		...commonDefaultRoute,
		as: 'talent',
	},
	'/avatarverification': {
		...commonDefaultRoute,
		as: 'avatarverification',
		isComponent: true,
	},
	'/webcamsessions': {
		...commonDefaultRoute,
		as: 'webcamsessions',
	},
	'/verification': {
		...commonDefaultRoute,
		as: 'verification',
	},
	'/schedule': {
		...commonDefaultRoute,
		as: 'schedule',
	},
	'/supporttickets': {
		...commonDefaultRoute,
		as: 'supporttickets',
	},
	'/': {
		...commonDefaultRoute,
		isComponent: true,
	},
};
